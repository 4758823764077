<template>
    <div class="border-1 border-bluegray-100 border-round-2xl p-3 flex flex-column gap-3">
        <span class="flex gap-2" style="font-family: Poppins-Medium, sans-serif">
            <span class="font-bold">Exame:</span>
            <span>{{ procedimento.name }}</span>
        </span>
        <span class="flex align-items-center gap-2" style="font-family: Poppins-Medium, sans-serif">
            <span class="font-bold"> Antecedência: </span>
            <span>
                {{ procedimento.dias_antecedencia === 1 ? `${procedimento.dias_antecedencia} dia` : `${procedimento.dias_antecedencia || 0} dias` }}
            </span>
        </span>
        <span class="flex align-items-center gap-2" style="font-family: Poppins-Medium, sans-serif">
            <span class="font-bold"> Data: </span>

            <Calendar
                v-if="!modoExibicao"
                v-model="data"
                dateFormat="dd/mm/yy"
                :minDate="dadosAgendamento.data_sugestao_inicial"
                :max-date="dadosAgendamento.data_sugestao_final"
                :disabled="tempoExpirado"
            />
            <span v-else>{{ procedimento.data_agendamento }}</span>
        </span>
        <span class="flex align-items-center gap-2" style="font-family: Poppins-Medium, sans-serif">
            <span class="font-bold"> Hora: </span>
            <Calendar
                v-if="!modoExibicao"
                ref="hora"
                v-model="hora"
                :showTime="true"
                :timeOnly="true"
                :disabled="tempoExpirado"
                @input="tratarInput($event)"
            />
            <span v-else>{{ procedimento.hora_agendamento }}</span>
        </span>
        <span v-if="periodoAgendamento" v-html="mensagemPeriodo" />
    </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
    data() {
        return {
            data: null,
            hora: null
        };
    },
    props: {
        modoExibicao: { type: Boolean, required: true },
        procedimento: { type: Object, required: true },
        dadosAgendamento: { type: Object, required: true },
        tempoExpirado: Boolean,
        periodoAgendamento: Number
    },
    computed: {
        mensagemPeriodo() {
            const periodos = {
                1: 'Manhã',
                2: 'Tarde',
                3: 'Ambos'
            };

            return `<span><b>Período de preferência:</b> ${periodos[this.periodoAgendamento]}</span>`;
        },
        _data() {
            if (!this.data) return this.data;
            return moment(this.data).format('YYYY-MM-DD');
        },
        _hora() {
            if (!this.hora) return this.hora;
            return moment.tz(this.hora, 'America/Sao_Paulo').format('HH:mm');
        }
    },
    methods: {
        tratarInput(event) {
            if (event.data === null) {
                return;
            }
            const horaSemFormato = event.target.value;
            if (horaSemFormato.length === 2 && !horaSemFormato.includes(':')) {
                this.hora = `${horaSemFormato}:`;
                this.$refs.hora.selectionStart = this.$refs.hora.selectionEnd = -1;
            }
            if (horaSemFormato.length !== 4 || horaSemFormato.includes(':')) {
                return;
            }
            this.hora = `${horaSemFormato[0]}${horaSemFormato[1]}:${horaSemFormato[2]}${horaSemFormato[3]}`;
            this.$refs.hora.selectionStart = this.$refs.hora.selectionEnd = -1;
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}
</style>
