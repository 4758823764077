<template>
    <div class="content-cnt">
        <CabecalhoTabela />
        <ItemTabela
            v-for="procedimento of funcionario.procedimentos"
            :key="procedimento.id"
            :ref="`procedimento-${procedimento.id}`"
            :modoExibicao="modoExibicao"
            :procedimento="procedimento"
            :dadosAgendamento="dadosAgendamento"
            :tempoExpirado="tempoExpirado"
        />
        <div v-if="!modoExibicao" class="w-full flex justify-content-end" style="padding: 16px">
            <Button
                label="Confirmar"
                class="p-button p-button-text p-button-rounded text-lg"
                style="color: #3f51b5; font-family: Poppins-Regular, sans-serif; font-weight: 600"
                @click="confirmarAgendamento"
                :disabled="tempoExpirado"
                :loading="loading"
            />
        </div>
        <Toast />
    </div>
</template>
<script>
import CabecalhoTabela from './components/CabecalhoTabela.vue';
import ItemTabela from './components/ItemTabela.vue';
import { logoutPrestador } from '@/services/auth';
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import moment from 'moment-timezone';
import { isHoraInHorarioComercial } from '@/utils/CalendarHorarioComercial';

export default {
    inject: ['confirmar'],
    emits: ['confirmado'],
    components: { CabecalhoTabela, ItemTabela },
    props: {
        modoExibicao: { type: Boolean, default: false },
        funcionario: { type: Object, required: true },
        tempoExpirado: Boolean
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        dadosAgendamento() {
            const { id_agendamento, periodo_agendamento, data_sugestao_inicial, data_sugestao_final } = this.funcionario;
            return {
                id_agendamento,
                periodo_agendamento,
                data_sugestao_inicial: moment(data_sugestao_inicial).toDate(),
                data_sugestao_final: moment(data_sugestao_final).toDate()
            };
        }
    },
    methods: {
        async confirmarAgendamento() {
            try {
                const procedimentos = this.funcionario.procedimentos.map((procedimento) => {
                    return {
                        dataAgendamento: this.$refs[`procedimento-${procedimento.id}`]._data,
                        horaAgendamento: this.$refs[`procedimento-${procedimento.id}`]._hora,
                        agendamentoId: this.funcionario.id_agendamento,
                        id: procedimento.id_agendamento_procedimento
                    };
                });
                if (procedimentos.some(({ horaAgendamento }) => !isHoraInHorarioComercial(horaAgendamento))) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Horário inválido',
                        detail: 'O horário de agendamento deve ser feito dentro do horário comercial, das 6h ás 19h',
                        life: 3000
                    });
                }
                if (procedimentos.some(({ dataAgendamento, horaAgendamento }) => !dataAgendamento || !horaAgendamento)) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Campos Inválidos',
                        detail: 'Há campos de data ou hora não preenchidos',
                        life: 3000
                    });
                }
                this.loading = true;
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                await getClientBase(authToken).post('/acesso-prestador/confirmar-procedimento', procedimentos);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Agendamento Confirmado',
                    detail: `Agendamento de ${this.funcionario.nome_funcionario} confirmado com sucesso!`,
                    life: 3000
                });
                this.$emit('confirmado');
                this.confirmar();
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                if (error.response.data.statusCode === 408) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Sistema indisponível, tente novamente mais tarde.',
                        life: 3000
                    });
                }
                if (error.response.data.details.response.code === 409) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Informações desatualizadas!',
                        detail: error.response.data.details.response.message,
                        life: 3000
                    });
                    this.loading = false;
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('../../../../../../public/fonts/poppins/Poppins-Regular.ttf');
}

.content-cnt {
    box-sizing: border-box;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
}
</style>
