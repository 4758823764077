<template>
    <div class="content-grid">
        <span>{{ procedimento.name }}</span>
        <span>
            {{ procedimento.dias_antecedencia === 1 ? `${procedimento.dias_antecedencia} dia` : `${procedimento.dias_antecedencia || 0} dias` }}
        </span>
        <Calendar
            v-if="!modoExibicao"
            class="calendario"
            v-model="data"
            dateFormat="dd/mm/yy"
            :minDate="dadosAgendamento.data_sugestao_inicial"
            :max-date="dadosAgendamento.data_sugestao_final"
            :disabled="tempoExpirado"
        />
        <span v-else>{{ procedimento.data_agendamento }}</span>
        <Calendar
            v-if="!modoExibicao"
            ref="hora"
            v-model="hora"
            :showTime="true"
            :timeOnly="true"
            :disabled="tempoExpirado"
            @input="tratarInput($event)"
            @blur="validaHorario"
            :minDate="_horaLimites.min"
            :maxDate="_horaLimites.max"
        />
        <span v-else>{{ procedimento.hora_agendamento }}</span>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import { horaLimitesCalendarPrimevue, horaLimites, isHoraInHorarioComercial } from '@/utils/CalendarHorarioComercial';

export default {
    data() {
        return {
            data: null,
            hora: null
        };
    },
    props: {
        modoExibicao: { type: Boolean, required: true },
        procedimento: { type: Object, required: true },
        dadosAgendamento: { type: Object, required: true },
        tempoExpirado: Boolean
    },
    computed: {
        _data() {
            if (!this.data) return this.data;
            return moment(this.data).format('YYYY-MM-DD');
        },
        _hora() {
            if (!this.hora) return this.hora;
            return moment.tz(this.hora, 'America/Sao_Paulo').format('HH:mm');
        },
        _horaLimites() {
            return horaLimitesCalendarPrimevue();
        }
    },
    methods: {
        tratarInput(event) {
            if (event.data === null) {
                return;
            }
            const horaSemFormato = event.target.value;
            if (horaSemFormato.length === 2 && !horaSemFormato.includes(':')) {
                this.hora = `${horaSemFormato}:`;
                this.$refs.hora.selectionStart = this.$refs.hora.selectionEnd = -1;
            }
            if (horaSemFormato.length !== 4 || horaSemFormato.includes(':')) {
                return;
            }
            this.hora = `${horaSemFormato[0]}${horaSemFormato[1]}:${horaSemFormato[2]}${horaSemFormato[3]}`;
            this.$refs.hora.selectionStart = this.$refs.hora.selectionEnd = -1;
        },
        validaHorario() {
            if (!isHoraInHorarioComercial(this.hora)) {
                const { inicioValido } = horaLimites();
                this.hora = inicioValido.toDate();
                this.$refs.hora.selectionStart = this.$refs.hora.selectionEnd = -1;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$rosa-primario: #3f51b5;

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

.content-grid {
    display: grid;
    grid-template-columns: 1fr 111px 85px 85px;
    gap: 24px;
    align-items: center;
    padding: 8px 16px;
    font-family: Poppins-Medium, sans-serif;
}
</style>
