<template>
    <div class="content-grid bg-bluegray-50">
        <span>Exames</span>
        <span class="flex align-items-center gap-2">
            <span> Antecedência </span>
            <i v-tooltip.top="'Dias de antecedência do exame em relação ao exame clínico'" class="pi pi-info-circle rosa-primario" />
        </span>
        <span class="flex align-items-center gap-2">
            <i class="pi pi-calendar" />
            <span> Data </span>
        </span>
        <span class="flex align-items-center gap-2">
            <i class="pi pi-clock" />
            <span> Hora </span>
        </span>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$rosa-primario: #3f51b5;

.content-grid {
    display: grid;
    grid-template-columns: 1fr 111px 85px 85px;
    gap: 24px;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px 8px 0 0;

    & > span {
        padding-right: 30px;
    }

    .rosa-primario {
        color: $rosa-primario;
    }
}
</style>
