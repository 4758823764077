<template>
    <Toast />
    <Dialog
        ref="dialog"
        v-model:visible="mostrarDetalhes"
        :closable="false"
        class="px-3 py-2 bg-white border-round-3xl"
        style="width: 500px; font-family: Poppins-Medium, sans-serif"
        modal
        dismissableMask
    >
        <template #header>
            <div class="flex justify-content-between align-items-center mb-1 w-full" style="height: 40px">
                <h2 class="text-bluegray-900 m-0 text-3xl md:text-4xl">Detalhes do funcionário</h2>
                <Button icon="pi pi-times" icon-class="text-pink-500" class="p-button-text p-button-lg p-button-rounded" @click="fecharDetalhes" />
            </div>
        </template>

        <template #default>
            <div class="text-bluegray-900 flex flex-column gap-5">
                <div
                    v-if="!agendamentoFuturo && !confirmado"
                    class="flex justify-content-between align-items-center gap-2 p-3 bg-bluegray-50 border-2 border-round border-bluegray-100"
                >
                    <span class="countdown-label font-semibold text-lg md:text-xl text-bluegray-700 px-2"> Tempo para agendar </span>
                    <span class="flex align-items-center gap-3">
                        <i class="pi pi-clock text-pink-500 text-xl md:text-2xl" />
                        <span class="flex flex-column justify-content-center" style="max-width: 100px">
                            <span class="text-lg md:text-xl font-semibold text-bluegray-700" style="font-family: Poppins-Regular, sans-serif">
                                <span>HH</span>
                                <span style="margin: 0 9px" />
                                <span>MM</span>
                                <span style="margin: 0 9px" />
                                <span>SS</span>
                            </span>
                            <span class="text-2xl md:text-3xl font-bold -mt-2 flex justify-content-between">
                                <span style="min-width: 27px" class="flex">{{ tempoParaAgendarFormatado.horas }}</span>
                                <span class="mx-1">:</span>
                                <span style="min-width: 27px" class="flex">{{ tempoParaAgendarFormatado.minutos }}</span>
                                <span class="mx-1">:</span>
                                <span style="min-width: 27px" class="flex">{{ tempoParaAgendarFormatado.segundos }}</span>
                            </span>
                        </span>
                    </span>
                </div>

                <div class="flex flex-column">
                    <div class="flex align-items-center gap-3">
                        <Avatar class="bg-bluegray-800 text-white" shape="circle" size="xlarge">
                            <span class="text-xl">{{ inicialNome }}</span>
                        </Avatar>
                        <span class="flex flex-column">
                            <span class="font-semibold text-xl md:text-2xl">{{ funcionario.nome_funcionario }}</span>
                            <span class="text-bluegray-500 text-md md:text-xl">{{ detalhes.codigo_soc }}</span>
                        </span>
                    </div>

                    <div class="info grid mt-4 row-gap-2">
                        <span class="col-7 flex align-items-center gap-3 py-0">
                            <i class="pi pi-file" />
                            <span class="text-md md:text-xl">{{ detalhes.tipoExame }}</span>
                        </span>
                        <span class="col-5 flex align-items-center gap-3 py-0">
                            <i class="pi pi-calendar" />
                            <span class="text-md md:text-xl">{{ moment(detalhes.data_nascimento).format('DD/MM/YYYY') }}</span>
                        </span>

                        <span class="col-7 flex align-items-center gap-3 py-0">
                            <i class="pi pi-briefcase" />
                            <span class="text-md md:text-xl">{{ setorECargo }}</span>
                        </span>
                        <span class="col-5 flex align-items-center gap-3 py-0">
                            <i class="pi pi-id-card" />
                            <span class="text-md md:text-xl">{{ $filters.cpf(detalhes.cpf) }}</span>
                        </span>
                    </div>

                    <div class="border-1 border-bluegray-100 border-round px-4 py-2 flex align-items-center text-md md:text-xl gap-2 mt-4">
                        <i class="pi pi-building text-2xl mr-1" />
                        <b>Unidade</b>
                        <span>{{ detalhes.unidade }}</span>
                    </div>

                    <!-- <div class="mt-5">
                        <div class="flex justify-content-between align-items-center">
                            <span class="text-xl md:text-2xl text-bluegray-500 info">
                                {{ !agendamentoFuturo && !confirmado ? 'Informe uma observação' : 'Observação' }}
                            </span>
                            <i class="pi pi-info-circle text-pink-500" v-tooltip.top="'Orientações ou recomendações para este agendamento'" />
                        </div>
                        <Textarea
                            rows="5"
                            class="mt-3 border-1 border-bluegray-100 w-full"
                            style="box-shadow: inset 2px -0.3em 1.5em #efefef; resize: none"
                            v-model.trim="detalhes.observacaoPrestador"
                            :readonly="agendamentoFuturo || confirmado"
                        />
                    </div> -->
                </div>
            </div>
        </template>

        <!-- <template #footer>
            <div v-if="!agendamentoFuturo && !confirmado" class="flex justify-content-end gap-4 mb-1">
                <Button
                    @click="fecharDetalhes"
                    :disabled="btnLoading"
                    label="Cancelar"
                    class="p-button-rounded p-button-lg p-button-outlined border-1 border-bluegray-200 text-xl"
                    style="padding: 0.9rem 1.8rem; font-family: Poppins-Medium, sans-serif; color: #3f51b5"
                />
                <Button
                    @click="salvarDetalhes"
                    :loading="btnLoading"
                    label="Salvar"
                    class="p-button-rounded p-button-lg text-xl"
                    style="padding: 0.9rem 1.8rem; background-color: #3f51b5; border: #3f51b5; font-family: Poppins-Medium, sans-serif"
                />
            </div>
        </template> -->
    </Dialog>
</template>

<script>
import { logoutPrestador } from '@/services/auth';
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import moment from 'moment-timezone';
import { capitalize } from 'vue';

export default {
    props: {
        funcionario: { type: Object, required: true },
        agendamentoFuturo: { type: Boolean, default: false },
        confirmado: { type: Boolean, default: false }
    },
    data() {
        return {
            mostrarDetalhes: false,
            detalhes: null,
            tempoParaAgendar: 0,
            intervalo: null,
            btnLoading: false
        };
    },
    computed: {
        inicialNome() {
            return this.funcionario.nome_funcionario.charAt(0).toUpperCase();
        },
        tempoParaAgendarFormatado() {
            const duration = moment.duration(this.tempoParaAgendar, 'seconds');
            const horas = Math.floor(duration.asHours()).toString().padStart(2, '0');
            const minutos = duration.minutes().toString().padStart(2, '0');
            const segundos = duration.seconds().toString().padStart(2, '0');

            return {
                horas,
                minutos,
                segundos
            };
        },
        setorECargo() {
            if (!this.detalhes.setor && !this.detalhes.cargo) return 'Não disponível';
            return `${capitalize(this.detalhes.setor.toLowerCase())} / ${capitalize(this.detalhes.cargo.toLowerCase())}`;
        }
    },
    methods: {
        moment,
        async acessarDetalhes() {
            await this.carregarDetalhes();
            this.mostrarDetalhes = true;
        },
        fecharDetalhes() {
            this.$refs.dialog.close();
        },
        async salvarDetalhes() {
            this.btnLoading = true;
            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                await getClientBase(authToken).post(`/acesso-prestador/salvar-observacao`, {
                    id: this.funcionario.id_agendamento,
                    observacaoPrestador: this.detalhes.observacaoPrestador
                });
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                if (error.response.data.details.response.code === 409) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Informações desatualizadas!',
                        detail: error.response.data.details.response.message,
                        life: 3000
                    });
                    this.btnLoading = false;
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            this.btnLoading = false;
            this.$refs.dialog.close();
        },
        async carregarDetalhes() {
            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const { data } = await getClientBase(authToken).get(`/acesso-prestador/funcionario/agendamento/${this.funcionario.id_agendamento}`);
                this.detalhes = data;
                this.recuperarTempoParaAgendar();
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
        },
        recuperarTempoParaAgendar() {
            if (!this.detalhes) return;
            this.tempoParaAgendar = this.detalhes.tempoParaAgendar;
            clearInterval(this.intervalo);
            this.intervalo = setInterval(() => {
                if (this.tempoParaAgendar <= 0) {
                    clearInterval(this.intervalo);
                } else {
                    this.tempoParaAgendar--;
                }
            }, 1000);
            if (this.tempoParaAgendar <= 0) {
                this.tempoParaAgendar = 0;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

.info {
    font-family: Poppins-Regular, sans-serif;
}
</style>
